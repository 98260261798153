<template>
  <div id="graduate" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(add)">
              <SdInput
                type="text"
                rules="required"
                label="Name"
                v-model="graduate.student.name"
                placeholder="Enter a name"
                disabled
              />

              <SdSelect
                type="text"
                rules="required"
                label="School Class"
                v-model="graduate.schoolClass.name"
              >
                <option
                  v-for="schoolClass in schoolClasses"
                  :key="schoolClass.id"
                >
                  {{ schoolClass.name }}
                </option>
              </SdSelect>

              <SdSelect
                type="text"
                rules="required"
                label="Term"
                v-model="graduate.term.title"
              >
                <option v-for="term in terms" :key="term.title">
                  {{ term.title }}
                </option>
              </SdSelect>

              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'
import TERMS from '../../../graphql/term/Terms.gql'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '@/assets/js/notification'

export default {
  name: 'graduate',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Graduate',
      skipQuery: true,
      graduate: {
        student: {
          id: null,
          name: null,
        },
        term: {
          id: null,
          title: null,
        },
        schoolClass: {
          id: null,
          name: null,
        },
      },
    }
  },
  apollo: {
    graduate: {
      query() {
        if (this.$route.params.id) {
          return gql`
            query GraduateQuery($id: ID!) {
              graduate(id: $id) {
                id
                student {
                  id
                  name
                }
                term {
                  id
                  title
                }
                schoolClass {
                  id
                  name
                }
              }
            }
          `
        }
      },
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
    schoolClasses: {
      query: SCHOOL_CLASSES,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
    terms: {
      query: TERMS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  watch: {
    graduate(data) {
      this.$store.commit('setSubMenus', [
        {
          name: 'Gradudates Info',
          route: `/school/${this.schoolId}/graduates_info/${data.term.id}`,
        },
      ])
    },
    'graduate.term.title'(value) {
      if (!this.terms) return
      if (value) {
        this.graduate.term.id = this.terms.filter(
          (term) => term.title === value
        )[0].id
      }
    },
    'schoolClass.name'(value) {
      if (!this.schoolClasses) return
      if (value) {
        this.graduate.schoolClass.id = this.schoolClasses.filter(
          (schoolClass) => schoolClass.name === value
        )[0].id
      }
    },
  },
  methods: {
    add() {
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateGraduateQuery(
                $termId: Int!
                $schoolClassId: Int!
                $studentId: Int!
              ) {
                createGraduate(
                  input: {
                    termId: $termId
                    schoolClassId: $schoolClassId
                    studentId: $studentId
                  }
                ) {
                  graduate {
                    id
                    termId
                    schoolClassId
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              termId: parseInt(this.graduate.term.id),
              schoolClassId: parseInt(this.graduate.schoolClass.id),
              studentId: parseInt(this.graduate.student.id),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createGraduate.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/graduates`
              )
            })
          })
          .catch((error) => {
            console.error(error.message)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateGraduateQuery(
                $id: Int!
                $termId: Int!
                $schoolClassId: Int!
              ) {
                updateGraduate(
                  input: {
                    id: $id
                    termId: $termId
                    schoolClassId: $schoolClassId
                  }
                ) {
                  graduate {
                    id
                    termId
                    schoolClassId
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.graduate.id),
              termId: parseInt(this.graduate.term.id),
              schoolClassId: parseInt(this.graduate.schoolClass.id),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateGraduate.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/graduates`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.skipQuery = false
    } else {
      const graduate = this.$store.getters.getGraduate

      this.graduate.student.id = graduate.student_id
      this.graduate.student.name = graduate.name
      this.graduate.term.id = graduate.term_id
      this.graduate.term.title = graduate.term_title
      this.graduate.schoolClass.id = graduate.school_class_id
      this.graduate.schoolClass.name = graduate.school_class_name
    }
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
  },
}
</script>

<style lang="scss" scoped></style>
style
